import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

BX24.fitWindow((data: any)=> {
    let x = BX24.getScrollSize();
    BX24.resizeWindow(x.scrollWidth, x.scrollHeight)
})

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);